<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="400">
    <v-card-title class="justify-center">Sign In</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="E-mail"
              placeholder="E-mail"
              solo
              required
              :rules="req"
              data-cy="email-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              solo
              required
              :rules="req"
              data-cy="password-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row d-flex justify="center">
            <v-btn
              class="d-flex justify-center mb-6"
              color="primary"
              min-width="100%"
              @click="emailLogin"
            >
              Sign In
            </v-btn>
            <v-btn
              class="d-flex justify-center mb-6"
              color="secondary"
              min-width="100%"
              to="/signup"
            >
              Sign Up
            </v-btn>
          </v-row>
        </v-card-actions>
        <!-- TODO: Matt please fix
         <v-row>
          <v-col>
            <div class="forgot">
              <router-link to="/reset-password">Forgot Password?</router-link>
            </div>
          </v-col>
        </v-row> -->
        <v-divider class="mt-2 mb-6"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#4285F4"
              @click="googleLogin"
            >
              <v-icon dark> mdi-google </v-icon>&nbsp; Sign In with Google
            </v-btn>
          </v-col>
        </v-row>
        <v-row d-flex justify="center" style="display: none">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#4267B2"
              @click="facebookLogin"
            >
              <v-icon dark> mdi-facebook </v-icon>&nbsp; Sign In with Facebook
            </v-btn>
          </v-col>
        </v-row>
        <v-row d-flex justify="center" style="display: none">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#1DA1F2"
              @click="twitterLogin"
            >
              <v-icon dark> mdi-twitter </v-icon>&nbsp; Sign In with Twitter
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}

.v-application a {
  text-align: center;
  width: 100%;
  text-decoration: none;
  color: #22abe1;
  display: flex;
  justify-content: center;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";
import firebase from "firebase/compat/app";

export default {
  name: "Login",
  mixins: [hasuraService],
  methods: {
    emailLogin: function() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          this.$store.state.is_login = true;
          result.user.getIdToken(true).then(function(token) {
            localStorage.setItem("token", token);
            window.location.href = "/seminar";
          });
        })
        .catch((error) => {
          console.log(error);
          var errorMessage = error.message;
          this.$store.commit("set_snackbar", errorMessage);
        });
    },
    twitterLogin: function() {
      console.log("twitterLogin");
    },
    facebookLogin: function() {
      console.log("facebookLogin");
    },
    googleLogin: function() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          console.log("googleLogin");
          this.$store.state.is_login = true;
          result.user.getIdToken(true).then(function(token) {
            localStorage.setItem("token", token);
            window.location.href = "/seminar";
          });
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        });
    },
  },
  data: () => {
    return {
      loading: false,
      email: "",
      password: "",
      valid: true,
      req: [(v) => !!v || "Field is required"],
    };
  },
};
</script>
